<template>
    <span
        class="label label-default"
        :class="{
            'implanted': status.id == 'IMPLANTED',
            'started': status.id == 'STARTED',
            'waiting-first-request': status.id == 'WAITING_REQUEST',
            'suspended': status.id == 'SUSPENDED',
            'on-alert': status.id == 'ON_ALERT',
            'on-watch':  status.id == 'ON_WATCH' }"
    >
        {{ status.name }}
    </span>
</template>

<script>
export default {
    props: [ "status" ],
    methods: { }
};
</script>
<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    span.label {
        border-radius: 4px;
        padding: 4px 6px !important;
        width: fit-content;
        text-align: center;
        font-size: 1em;
        font-weight: 700;
        display: inline-block;
        text-transform: uppercase;
        flex: 1;
        min-height: 23px;
        color: #707d76 !important;
        /* box-shadow: 2px 2px lightgray; */
        &.on-watch {
            background-color: #2AD0621A !important;
            color: #187C3B !important;
            text-transform: uppercase;
        }
        &.started {
            background-color: #F1F1F1 !important;
            color: #404040 !important;
            text-transform: uppercase;
        }
        &.waiting-first-request {
            background-color: #FF71101A !important;
            color: #FF7110 !important;
        }
        &.implanted {
            background-color: #17A2B81A !important;
            color: #0677C9 !important;
        }
        &.on-alert {
            background-color: #FFDD20 !important;
            color: #404040 !important;
        }
        &.suspended{
            background-color: #E535351A !important;
            color: #CD2A2A !important;
        }
    }

    .pedidoGerado{
    background-color: #3899DC !important;
    }
</style>
