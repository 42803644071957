<template>
    <div class="client-row-container">
        <div style="margin-bottom: 1vh;" class="client-row-text">
            <strong class="page-table-header-mobile ">{{ client.head_office ? client.head_office.social_reazon : ''
            }}</strong>
            {{ client.group_name }}
        </div>
        <div class="client-column-container">
            <div class="client-row-text">
                <h6 class="page-table-header-mobile ">DT Cadastro</h6>
                <span >{{ client.created_at | formatDateOnly }}</span>
            </div>
            <div class="client-row-text mobile-center">
                <h6 class="page-table-header-mobile ">CNPJ / Telefone</h6>
                <span >{{ client.cnpj || "" | VMask('## ### ###/####-##') }}</span> 
                <cc-whatsapp v-if="client.phone" style="padding: 0 !important;" :phone="client.phone" />
                <span v-else>--</span>
            </div>
            <div class="client-row-text mobile-end">
                <h6 class="page-table-header-mobile ">Revenda </h6>
                <div>
                    {{ client.resale ? client.resale.name : '' }} 
                </div>
                
            </div>
        </div>
        <div class="client-column-container" style="margin-bottom: 2vh;">
            <div>
                <h6 class="page-table-header-mobile ">Responsável</h6>
                <div class="change-status">
                    <select class="page-table-input-select" v-model="client.attendance.attendant"
                        v-on:change="updateAttendance(client)">
                        <option :value="client.attendance.attendant" disabled>{{ client.attendance.attendant.name }}
                        </option>
                        <option v-for="manager in arraySelectAttendant" :key="manager.value.id" :value="manager.value">
                            {{ manager.text }}</option>
                    </select>
                    <i class="fa fa-check success-update" :class="{ visible: client.saved }" />
                </div>
            </div>
            <div class="mobile-end">
                <h6 class="page-table-header-mobile ">Status</h6>
                <div class="manage-status">
                    <div class="change-status">
                        <select class="page-table-input-select" v-model="client.attendance.status"
                            v-on:change="updateAttendance(client)">
                            <option v-for="status in statuses" :key="status.id" :value="status">{{ status.name }}</option>
                        </select>
                        <AttendanceStatusCircle style="margin-left: 10px;" :status="client.attendance.status.id" />
                    </div>
                </div>
            </div>
        </div>
        <div class="client-column-container">
            <span title="Comentários" class="material-icons-outlined answers-icon" @click="action1(client)">
                question_answer
            </span>
            <router-link style="font-size: inherit;" :to="{
                name: 'monitors-page',
                params: { tab: 'cotacoes' },
                query: { client_id: client.id, name: client.name }
            }" :target="'_blank'">
                <span class="material-icons-outlined monitor-icon">
                    desktop_windows
                </span>
            </router-link>
            <span title="Convites" @click="() => open_invitations(client)" class="material-icons-outlined invite-icon"
                v-if="can('RESALE_ID', 'READ')">
                email
            </span>
            <span @click="openEditAttachedFilesModal(client)" title="Ver arquivos anexados"
                class="material-icons-outlined attach-file-icon">
                attach_file
            </span>
            <span @click="edit(client)" v-if="can('CLIENT', 'UPDATE')" title="Editar"
                class="material-icons-outlined edit-user-icon">
                edit
            </span>
        </div>
    </div>
</div></template>

<script>
import AttendanceStatusCircle from "@/components/shared/AttendanceStatusCircle";
import { perm_mixin } from '@/mixins/permission.mixin'


export default {
    name: 'MobileClientRow',
    props: ['client', 'arraySelectAttendant', 'updateAttendance', 'statuses',
        'action1', 'open_invitations', 'openEditAttachedFilesModal', 'edit'],
    mixins: [perm_mixin],
    components: {
        AttendanceStatusCircle
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/commons/_tables';

.client-row-container {
    display: none;
}

@media only screen and (max-width: 630px) {
    .client-row-container {
        display: block;
        padding: 2vh 0;
        font-size: 3.5vw;
    }

    .client-column-container {
        display: flex;
        justify-content: space-between;
    }

    .page-table-header-mobile {
        font-weight: 600;
        font-size: 1.19em;
        color: #505050;
        display: flex;
    }

    .client-row-text {
        color: #505050;
    }

    .change-status {
        display: flex;
        align-items: center;
        width: 100%;

        .success-update {
            margin-left: 5px;
            visibility: hidden;
            color: $brand-success;

            &.visibile {
                visibility: 'visible';
            }
        }
    }

    .page-table-input-select {
        padding: 1vh 1vw;
        border-radius: 10px;
        color: #35495e;
        border: 1px solid #E5E5E5;

        &:focus {
            outline: none;
        }

        width: 90%;
    }

    .answers-icon {
        font-size: 1.5em;
        color: #46AD5D;
        cursor: pointer;
        margin-right: 5px;
    }

    .monitor-icon {
        font-size: 1.5em;
        color: #FF7110;
        cursor: pointer;
        margin-right: 5px;
    }

    .invite-icon {
        font-size: 1.5em;
        color: #FF7110;
        cursor: pointer;
        margin-right: 5px;
    }

    .attach-file-icon {
        font-size: 1.5em;
        color: #404040;
        cursor: pointer;
    }

    .edit-user-icon {
        font-size: 1.7em;
        color: #606060;
        cursor: pointer;
    }
    .mobile-end{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .mobile-center{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}</style>